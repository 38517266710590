
<script>
import config from "../../config";
import pagination from "laravel-vue-pagination";
import PageHeader from "../../components/page-header";
import { bannerService } from "../../services";
import { error_message,debounce } from "../../Helper/helper";
import draggable from "vuedraggable";

export default {
  components: { pagination, bannerService, PageHeader,draggable },
  data() {
    const { status,orderBy,sortBy} = this.$route.query;
    return {
      sortBy:sortBy || "reorder",
      sortDesc: orderBy && orderBy != '' ? true : orderBy,
      banners: [],
      paginations: {},
      bannerCount: '',
      limit: 1,
      search: "",
      status: status == undefined ? 1 :status,
      config: config,
      loading: true,
      searchData:debounce(()=>{
        if(!this.init){
          const {status,orderBy,sortBy} = this.$data;
          this.$router.replace({name: 'banner-list', query: {status,orderBy,sortBy}})
        }
        this.getBanner();
        this.init = false;
      },500),
      
      fields: [
        { key: "image", label: "#", sortable: false },
        { key: "name", label: this.trans.get("__JSON__.Name"), sortable: true },
        { key: "type", label: this.trans.get("__JSON__.Type"), sortable: true },
        { key: "restaurants", label: "Vendor Name", sortable: false},
        // {
        //   key: "status",
        //   label: this.trans.get("__JSON__.Status"),
        //   sortable: false,
        // },
        {
          key: "action",
          label: this.trans.get("__JSON__.Action"),
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    getBanner(page) {
      this.loading = true;
      var filters = {
        search: this.search,
        status: this.status,
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "desc" : "asc",
        page: page && page > 0 ? page : 1,
      }
      bannerService
        .getBanner(filters)
        .then((response) => {
          this.loading = false;
          this.paginations = response.data.result;
          this.banners = response.data.result.data.data;
          this.bannerCount = response.data.result.bannerCount;
        });
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getBanner();
    },
    deleteById(id) {
      if (confirm("Are you sure you want to delete?")) {
        bannerService.deletebanner({ id: id }).then((response) => {
          error_message(response.data.message);
          this.getBanner();
        });
      }
    },
    updateBannerOrder: function() {
      var items = this.banners.map(function(item, index) {
        return { item: item, order: index }
      });
      if(this.debounce) return
      var ids = items.map(item => item.item.id);

      this.debounce = setTimeout(function(items) {
        this.debounce = false;
        bannerService.updateBannerOrder({ banner_ids : ids})
        .then(response => {
          if (response.data.code === 200) {
            this.getBanner();
          }
        });
      }.bind(this, items), 10)
    }
  },
};
</script>

<template>
  <div>
    <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Banners') }}({{bannerCount}})</h4>
              </div>
              <div class="page-title-right">
                  <router-link v-if="$auth.hasPermission(config.permissions.banner_write)" :to="{name: 'banner-add'}" class="btn btn-success btn-rounded mr-2 add-btn">
                  <i class="mdi mdi-plus mr-1"></i> {{ trans.get('__JSON__.Add Banner') }}
                  </router-link>
                  <router-link v-if="$auth.hasPermission(config.permissions.banner_write)" :to="{name: 'banner-add'}" class="btn btn-success btn-rounded mr-2 m-add-btn">
                  <i class="mdi mdi-plus"></i>
                  </router-link>
              </div>
              </div>
          </div>
      </div>
    <!-- end row -->
    <div class="row list-table-main">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 offset-sm-8">
                <div class="form-group">
                  <div class="date-range-list">
                    <label>{{ trans.get('__JSON__.Status') }} :</label>
                    <select class="custom-select" v-model="status" @change="searchData()">
                      <option value="">{{ trans.get('__JSON__.All') }}</option>
                      <option value="1" selected>{{ trans.get('__JSON__.Active') }}</option>
                      <option value="0">{{ trans.get('__JSON__.Inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="date-range-list">
                  <label>{{ trans.get('__JSON__.Search') }} :</label>
                  <div class="text-sm-right">
                    <div class="search-box mr-2 d-inline-block">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @input="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Placeholder')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-main-list banner-table table-responsive custom-tbl banner">
                <!-- <b-table
                  :items="banners"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :no-local-sorting="true"
                  @sort-changed="sortingChanged"
                  :busy="loading"
                  show-empty
                  >
                  <template #table-busy>
										<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>{{trans.get('__JSON__.Loading...')}}</strong>
										</div>
									</template>
                  
                  <template v-slot:cell(image)="data">
                    <div v-if="data.item.image">
                      <img
                        alt
                        title="product-img"
                        class="avatar-md rounded-circle-banner"
                        :src="config.bannerpath+data.item.image"
                         @error="config.no_image"
                      />
                    </div>
                    <div v-else>
                      <img
                        class="avatar-md rounded-circle-banner"
                        :src="config.no_image"
                        alt
                      />
                    </div>
                  </template>

                  <template v-slot:cell(name)="data">
                    <h5 class="font-size-14">
                      <div v-if="data.item.name > 15">{{data.item.name.substr(0, 15)+ '...'}}</div>
                      <div v-else>{{data.item.name}}</div>
                    </h5>
                  </template>

                  <template v-slot:cell(restaurants)="data">
                   <template v-for="(banners,index) in data.value">
                      <span :key="banners.id">{{banners.name}}</span>
                      <span :key="banners.id" v-if="(index+1) != data.value.length">, </span>
                    </template>
                  </template>

                  <template v-slot:cell(status)="data">
                    <div
                      v-if="data.item.status === '1'"
                      class="badge badge-pill badge-soft-success font-size-12"
                    >{{ trans.get('__JSON__.Active') }}</div>
                    <div
                      v-else
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >{{ trans.get('__JSON__.Inactive') }}</div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <div class="vendor-btn">
                      <router-link
                        v-if="$auth.hasPermission(config.permissions.banner_write)"
                        :to="{name: 'banner-edit',params: { id: base64(data.item.id) }}"
                        class="btn btn-success btn-sm btn-rounded"
                      >{{ trans.get('__JSON__.Edit') }}</router-link>
                      <button
                        v-if="$auth.hasPermission(config.permissions.banner_write)"
                        type="submit"
                        class="btn btn-danger btn-sm btn-rounded ml-1"
                        @click="deleteById(base64(data.item.id))"
                      >{{ trans.get('__JSON__.Delete') }}</button>
                    </div>
                  </template>

                  <template #empty>
                    <p class="text-center">{{ trans.get('__JSON__.No Banner') }}</p>
                  </template>
                </b-table> -->

                <div class="card task-box custom-tblheader">
                  <div class="card-body menu-items">
                    <div class="media">
                      <div class="tbl-heading mr-4 font-weight-bolder"> # </div>
                      <div class="tbl-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Name') }} </div>
                      <div class="tbl-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Type') }} </div>
                      <div class="tbl-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Description') }} </div>
                      <!-- <div class="tbl-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Status') }} </div> -->
                      <div class="tbl-heading mr-4 font-weight-bolder"> {{ trans.get('__JSON__.Action') }} </div>
                    </div>
                  </div>
                </div>
                <draggable class="list-group" group="banner" :list="banners" v-model="banners" @end="updateBannerOrder">
                  <div v-for="banner, index in banners" :key="banner.id" class="card task-box">
                    <div class="card-body menu-items drag-item">
                      <div class="media">
                        <div class="avatar-md tbl-heading mr-4">
                          <h5 class="font-size-14">{{(paginations.data.current_page -1) * paginations.data.per_page + index + 1 }}</h5>
                        </div>

                        <div class="tbl-heading mr-4">
                          <h5 class="font-size-14">{{banner.name | truncate(25, '...')}}</h5><br>
                          <div v-if="banner.status == '1'"  class="badge badge-pill badge-soft-success font-size-12" >{{ trans.get('__JSON__.Active') }}</div>
                          <div v-else class="badge badge-pill badge-soft-danger font-size-12" >{{ trans.get('__JSON__.Inactive') }}</div>
                        </div>

                        <div class="tbl-heading mr-4">
                          <h5 class="font-size-14">{{banner.type}}</h5><br>
                        </div>

                        <div class="avatar-md tbl-heading mr-4">
                          <span class="text-danger font-size-16" v-bind:id="banner.id" >
                          <div v-if="banner.image">
                            <img title="product-img" class="avatar-md rounded-circle-banner" :src="config.bannerpath+banner.image" alt height="30" @error="$event.target.src=config.no_image">
                          </div>
                          <div v-else>
                            <img class="avatar-md rounded-circle-banner" :src="config.no_image">
                          </div>
                          </span>
                        </div>

                        <div class="tbl-heading mr-4">
                          <h5 class="font-size-14">
                            {{
                              (banner.type == 'restaurant') ? ($auth.isProductMarket() ? trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name+'/s' }) : trans.trans('__JSON__.Vendor',{ vendor: $auth.setting.restaurant_name})) :
                              ( 
                                (banner.type == 'link') ? trans.get('__JSON__.Webpage') : 
                                (
                                  (banner.type == 'noaction') ? trans.get('__JSON__.Blank (No Action)') : ''
                                )
                              )
                            }}
                          </h5>
                        </div>

                        <div class="tbl-heading mr-4">
                          <span v-for="(ban,index) in banner.restaurants" :key="ban.id">
                            <span :key="ban.id">{{ban.name}}</span>
                            <span :key="ban.id" v-if="(index+1) != banner.restaurants.length">, </span>
                          </span>
                        </div>

                        <!-- <div class="tbl-heading mr-4">
                          <div v-if="banner.status == '1'"  class="badge badge-pill badge-soft-success font-size-12" >{{ trans.get('__JSON__.Active') }}</div>
                          <div v-else class="badge badge-pill badge-soft-danger font-size-12" >{{ trans.get('__JSON__.Inactive') }}</div>
                        </div> -->

                        <div class="tbl-heading mr-4">
                          <router-link
                              v-if="$auth.hasPermission(config.permissions.banner_write)"
                              :to="{name: 'banner-edit',params: { id: base64(banner.id) }}"
                              class="btn btn-success btn-sm btn-rounded"
                            >{{ trans.get('__JSON__.Edit') }}</router-link>

                          <button
                              v-if="$auth.hasPermission(config.permissions.banner_write)"
                              type="submit"
                              class="btn btn-danger btn-sm btn-rounded ml-1"
                              @click="deleteById(base64(banner.id))"
                            >{{ trans.get('__JSON__.Delete') }}</button>
                        </div>

                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
              <div class="row col-sm-5 offset-sm-7">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <pagination
                        :data="paginations"
                        :limit="limit"
                        @pagination-change-page="getBanner"
                      ></pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style>
.banner-table .table thead th:nth-child(1),.banner-table .table thead th:nth-child(3),.banner-table .table thead th:nth-child(4),.banner-table .table thead th:nth-child(5){
  pointer-events: none;
}
.custom-tblheader .card-body{
    height: auto;
    background-color: #b3b0b033 !important;
    border-bottom: 0 solid #b3b0b033 !important;
}
.custom-tbl .media{
    align-items: center;
}
.banner .tbl-heading:first-child{
    width: 4.5rem;
}

.banner .tbl-heading:nth-child(2){
    width: 6rem;
}
.banner .tbl-heading:nth-child(3){
    width: 10rem;
}
.banner .tbl-heading:nth-child(4){
    width: 25rem;
}
/* .banner .tbl-heading:nth-child(5){
    width: 6rem;
} */
.drag-item.menu-items{
  height: auto;
}
</style>