<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DateRangePicker from "vue2-daterange-picker";
import pagination from "laravel-vue-pagination";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  get_partner_login,
  set_partner,
  set_partner_details,
  set_user,
  set_user_detail,
  debounce,
  get_decimal,
  get_currency,
  date_ranges
} from "../../Helper/helper";
import moment from "moment";
import { reportService, deliveryZoneService } from "../../services";
import { join } from "path";
/**
 * Report component
 */
export default {
  components: { Layout, PageHeader, pagination, DateRangePicker },
  data() {
    return {
      decimal: get_decimal(),
      totalProduct: {},
      restaurant: [],
      totalProductReport: [],
      restaurant_count: "",
      restaurants_id: "",
      sortBy: "id",
      search: "",
      sortDesc: true,
      paginations: {},
      limit: 2,
      perPage: 0,
      currentPage: 1,
      currency: get_currency(),
      loading: true,
      isDownloading: false,
      fields: [
        { key: "order_id", label: "Order Id", sortable: false },
        { key: "created_at", label: "Date", sortable: false },
        { key: "item_id", label: "Product Id", sortable: false },
        { key: "product_name", label: "Product Name", sortable: false },
        { key: "store_name", label: "Store Name", sortable: false },
        { key: "old_mrp", label: "Old Mrp", sortable: false },
        { key: "new_mrp", label: "New Mrp", sortable: false },
      ],
    };
  },
  mounted() {
    this.getTotalProductsReports();
  },
  methods: {
    downloadsCSV: function () {
      let totalProductReport =
        '\ufeff' +
        'S.No.,Order Id,Date,Product Id,Product Name,Store name,Old Mrp,New Mrp, \n'
      this.totalProductReport.forEach((el, ind) => {
        let sno = ind + 1;
        let date = moment(el["created_at"]).format('D MMM,Y hh:mm A').split(',').join('');
        var line = sno + "," +
        el["order_id"] +
        "," +
         date +
          "," +
          el["restaurant_menu_item_id"] +
          "," +
          el["product_name"].split(',').join('') +
          "," +
          el["name"].split(',').join('') +
          "," +
          el["product_old_mrp"] +
          "," +
          el["product_new_mrp"] +
          "\n"
          totalProductReport += line;
      });

      var blob = new Blob([totalProductReport], { type: "csv/plain" });
      var date = new Date().toLocaleString();
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "product_" + date + ".csv";
      link.click();
    },
    getTotalProductsReports(page) {
      this.loading = true;
      reportService
      .getUpdateItemPriceReport({
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        total_record:10,
        search:this.search,
        page: page ? page : 1,
      })
      .then((response) => {
        this.loading = false;
        // this.paginations = response.data.result;
        this.totalProduct = response.data.result;
      });
    },
    totalProductExportCsv() {
      this.isDownloading = true;
      reportService
      .updateItemPriceReportExportToCSV({ 
        sortBy: this.sortBy,
        orderBy: this.sortDesc ? "asc" : "desc",
        total_record:10,
        search:this.search,
      })
      .then((response) => {
        this.isDownloading = false;
        this.totalProductReport = response.data.result;
        this.downloadsCSV();
      });
    },
    searchData() {
      this.getTotalProductsReports();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.getTotalProductsReports();
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <div class="row">
          <div class="col-12">
              <div
              class="page-title-box d-flex align-items-center justify-content-between icon-main-page"
              >
              <div class="page-title-left avatar-main-icon">
                  <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                      <i class="bx bx-purchase-tag"></i>
                  </span>
                  </div>
                  <div class="report-tag">
                    <h4 class="mb-0 font-size-18">{{ trans.get('__JSON__.Product MRP Update Report') }}</h4>
                    <p><router-link :to="{ name: 'reports-list'}">{{ trans.get('__JSON__.Reports') }}</router-link> > {{ trans.get('__JSON__.Product MRP Update Report') }}</p>
                  </div>
              </div>
              <div class="page-title-right">
                <button @click="totalProductExportCsv" class="btn btn-primary btn-rounded export-btn" v-if="isDownloading" disabled>{{ trans.get('__JSON__.Exporting...') }}</button>
                <button @click="totalProductExportCsv" class="btn btn-primary btn-rounded export-btn" v-else>{{ trans.get('__JSON__.Export To CSV') }}</button>
                <button @click="totalProductExportCsv" class="btn btn-primary btn-rounded m-export-btn"><i class="bx bx-archive-in"></i></button>
              </div>
              </div>
          </div>
      </div>

      <!-- end row -->
      <div class="row">
        <div class="col-12 list-table-main">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-2 offset-sm-10">
                  <div class="search-box mr-2 mb-0 d-block form-group">
                    <div class="date-range-list">
                      <label>{{trans.get('__JSON__.Search')}} :</label>
                      <div class="position-relative d-inline-block w-100">
                        <input
                          type="text"
                          class="form-control"
                          id="search"
                          @keyup="searchData()"
                          v-model="search"
                          :placeholder="trans.get('__JSON__.Search Product Name')"
                        />
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 table-main-list total-sales-report-table table-responsive">
                  <b-table
                    :items="totalProduct.data"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :no-local-sorting="true"
                    @sort-changed="sortingChanged"
                    :busy="loading"
                    show-empty
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{trans.get('__JSON__.Loading...')}}</strong>
                      </div>
                    </template>

                    <template v-slot:cell(order_id)="data">
                      <div>{{ data.item.order_id }}</div>
                    </template>

                    <template v-slot:cell(created_at)="data">
                      <div>{{moment(data.item.created_at).format('D MMM,Y hh:mm A')}}</div>
                    </template>

                    <template v-slot:cell(item_id)="data">
                      <div>{{ data.item.restaurant_menu_item_id }}</div>
                    </template>

                    <template v-slot:cell(product_name)="data">
                      <div>{{ (data.item.product_name).split(',').join('') }}</div>
                    </template>

                    <template v-slot:cell(store_name)="data">
                      <div>{{ (data.item.name).split(',').join('') }}</div>
                    </template>

                    <template v-slot:cell(old_mrp)="data">
                      <span>{{ data.item.product_old_mrp }}</span>
                    </template>

                    <template v-slot:cell(new_mrp)="data">
                      <span>{{ data.item.product_new_mrp }}</span>
                    </template>

                    <template #empty>
                      <p class="text-center">{{ trans.get('__JSON__.No Product MRP Update Report Found') }}</p>
                    </template>
                  </b-table>
                </div>
                <div class="row col-sm-5 offset-sm-7">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <pagination
                          :data="totalProduct"
                          :limit="limit"
                          @pagination-change-page="getTotalProductsReports"
                        ></pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<style>
.total-sales-report-table .table thead th:nth-child(1),.total-sales-report-table .table thead th:nth-child(3),.total-sales-report-table .table thead th:nth-child(4){
    pointer-events: none;
}
</style>